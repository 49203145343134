var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"addBusiness"}},[_c('div',{staticClass:"page-container addBusiness-fix "},[_c('validation-observer',{ref:"rule"},[_c('div',{staticClass:"infomation-and-adress"},[_c('custtom-title',{attrs:{"icon":'InfoIcon',"title":'Thông tin'}}),_c('div',{staticClass:"infomation-and-adress__input__01"},[_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Người nộp hồ sơ doanh nghiệp "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{ref:"officalProfileId",attrs:{"rules":"required","custom-messages":{required: "Người nộp hồ sơ doanh nghiệp là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (value) { return value.id; },"label":"name","state":errors.length > 0 ? false:null,"options":_vm.dataOfficalProfile || [],"placeholder":"Người nộp hồ sơ doanh nghiệp"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataCreateLicense.officalProfileId),callback:function ($$v) {_vm.$set(_vm.dataCreateLicense, "officalProfileId", $$v)},expression:"dataCreateLicense.officalProfileId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"basicInput"}},[_vm._v("Người lao động "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"reportTypeId","rules":"required","custom-messages":{required: "Người lao động là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"reduce":function (title) { return title.id; },"label":"name","options":_vm.dataWorkerNotRegister || [],"placeholder":'Người lao động',"state":errors.length > 0 ? false:null},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataCreateLicense.workerId),callback:function ($$v) {_vm.$set(_vm.dataCreateLicense, "workerId", $$v)},expression:"dataCreateLicense.workerId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Nơi đăng ký nhận giấy phép "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":{required: "Nơi đăng ký nhận giấy phép là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.dataCreateLicense.locationRegister),callback:function ($$v) {_vm.$set(_vm.dataCreateLicense, "locationRegister", $$v)},expression:"dataCreateLicense.locationRegister"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Số giấy đề nghị cấp phép lao động "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":{required: "Số giấy đề nghị cấp phép lao động là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.dataCreateLicense.numberRequire),callback:function ($$v) {_vm.$set(_vm.dataCreateLicense, "numberRequire", $$v)},expression:"dataCreateLicense.numberRequire"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Ngày bắt đầu "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":{required: "Ngày bắt đầu là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"disabledInput":true,"state":errors.length > 0 ? false : null},model:{value:(_vm.dataCreateLicense.dateStartString),callback:function ($$v) {_vm.$set(_vm.dataCreateLicense, "dateStartString", $$v)},expression:"dataCreateLicense.dateStartString"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Ngày kết thúc "),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":{required: "Ngày kết thúc là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"disabledInput":true,"state":errors.length > 0 ? false : null},model:{value:(_vm.dataCreateLicense.dateEndString),callback:function ($$v) {_vm.$set(_vm.dataCreateLicense, "dateEndString", $$v)},expression:"dataCreateLicense.dateEndString"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('custtom-title',{attrs:{"icon":'FileTextIcon',"title":'Văn bản chấp thuận sử dụng người dụng người lao động nước ngoài'}}),_c('div',{staticClass:"infomation-and-adress__input__01"},[_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Số văn bản "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":{required: "Số văn bản là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.dataCreateLicense.numberUsingWorker),callback:function ($$v) {_vm.$set(_vm.dataCreateLicense, "numberUsingWorker", $$v)},expression:"dataCreateLicense.numberUsingWorker"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',[_c('label',[_vm._v("Người ra văn bản "),_c('span',{staticClass:"text-danger"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":{required: "Người ra văn bản là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.dataCreateLicense.usingWorkerCreateBy),callback:function ($$v) {_vm.$set(_vm.dataCreateLicense, "usingWorkerCreateBy", $$v)},expression:"dataCreateLicense.usingWorkerCreateBy"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Ngày ra văn bản")]),_c('date-time-picker',{attrs:{"disabledInput":true},model:{value:(_vm.dataCreateLicense.dateUsingWorkerString),callback:function ($$v) {_vm.$set(_vm.dataCreateLicense, "dateUsingWorkerString", $$v)},expression:"dataCreateLicense.dateUsingWorkerString"}})],1)],1)],1)],1)],1)]),_c('div',{staticClass:"add-business__button"},[_c('div',{staticClass:"add-business__button__item"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.handleCreate}},[_vm._v(" Lưu lại ")])],1),_c('div',{staticClass:"add-business__button__item"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary"},on:{"click":_vm.close}},[_vm._v(" Quay về ")])],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }