<template>
  <div
    id="addBusiness"
  >
    <div class="page-container addBusiness-fix ">
      <validation-observer ref="rule">
        <div class="infomation-and-adress">
          <!--===============  Thông tin =================-->
          <custtom-title
            :icon="'InfoIcon'"
            :title="'Thông tin'"
          />
          <div class="infomation-and-adress__input__01">
            <b-row>
              <!--===============  Người nộp hồ sơ doanh nghiệp  =================-->
              <b-col
                md="4"
                xl="4"
              >
                <b-form-group>
                  <label>Người nộp hồ sơ doanh nghiệp <span class="required">(*)</span></label>
                  <validation-provider
                    ref="officalProfileId"
                    #default="{ errors }"
                    rules="required"
                    :custom-messages="{required: `Người nộp hồ sơ doanh nghiệp là bắt buộc`}"
                  >
                    <v-select
                      v-model="dataCreateLicense.officalProfileId"
                      :reduce="(value) => value.id"
                      label="name"
                      :state="errors.length > 0 ? false:null"
                      :options="dataOfficalProfile || []"
                      placeholder="Người nộp hồ sơ doanh nghiệp"
                    >
                      <template v-slot:no-options="{ searching }">
                        <template v-if="searching">
                          Không có kết quả.
                        </template>
                        <em
                          v-else
                        >Không có dữ liệu</em>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--===============  Người lao động =================-->
              <b-col
                md="4"
                xl="4"
              >
                <b-form-group>
                  <label for="basicInput">Người lao động <span class="required">(*)</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="reportTypeId"
                    rules="required"
                    :custom-messages="{required: `Người lao động là bắt buộc`}"
                  >
                    <v-select
                      v-model="dataCreateLicense.workerId"
                      :reduce="title => title.id"
                      label="name"
                      :options="dataWorkerNotRegister || []"
                      :placeholder="'Người lao động'"
                      :state="errors.length > 0 ? false:null"
                    >
                      <template v-slot:no-options="{ searching }">
                        <template v-if="searching">
                          Không có kết quả.
                        </template>
                        <em
                          v-else
                        >Không có dữ liệu</em>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--===============  Nơi đăng ký nhận giấy phép =================-->
              <b-col
                md="4"
                xl="4"
              >
                <b-form-group>
                  <label>Nơi đăng ký nhận giấy phép <span class="text-danger">(*)</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :custom-messages="{required: `Nơi đăng ký nhận giấy phép là bắt buộc`}"
                  >
                    <b-form-input
                      v-model="dataCreateLicense.locationRegister"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--===============  Số giấy đề nghị cấp phép lao động =================-->
              <b-col
                md="4"
                xl="4"
              >
                <b-form-group>
                  <label>Số giấy đề nghị cấp phép lao động <span class="text-danger">(*)</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :custom-messages="{required: `Số giấy đề nghị cấp phép lao động là bắt buộc`}"
                  >
                    <b-form-input
                      v-model="dataCreateLicense.numberRequire"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--===============  Ngày bắt đầu =================-->
              <b-col
                md="4"
                xl="4"
              >
                <b-form-group>
                  <label for="InputHelp">Ngày bắt đầu <span class="required">(*)</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :custom-messages="{required: `Ngày bắt đầu là bắt buộc`}"
                  >
                    <date-time-picker
                      v-model="dataCreateLicense.dateStartString"
                      :disabledInput="true"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--=============== Ngày kết thúc =================-->
              <b-col
                md="4"
                xl="4"
              >
                <b-form-group>
                  <label for="InputHelp">Ngày kết thúc <span class="required">(*)</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :custom-messages="{required: `Ngày kết thúc là bắt buộc`}"
                  >
                    <date-time-picker
                      v-model="dataCreateLicense.dateEndString"
                      :disabledInput="true"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <!--===============  Tiền lương =================-->
          <custtom-title
            :icon="'FileTextIcon'"
            :title="'Văn bản chấp thuận sử dụng người dụng người lao động nước ngoài'"
          />
          <div class="infomation-and-adress__input__01">
            <b-row>
              <!--=============== Số văn bản=================-->
              <b-col
                md="4"
                xl="4"
              >
                <b-form-group>
                  <label>Số văn bản <span class="text-danger">(*)</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :custom-messages="{required: `Số văn bản là bắt buộc`}"
                  >
                    <b-form-input
                      v-model="dataCreateLicense.numberUsingWorker"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--=============== Người ra văn bản=================-->
              <b-col
                md="4"
                xl="4"
              >
                <b-form-group>
                  <label>Người ra văn bản <span class="text-danger">(*)</span></label>
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :custom-messages="{required: `Người ra văn bản là bắt buộc`}"
                  >
                    <b-form-input
                      v-model="dataCreateLicense.usingWorkerCreateBy"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!--=============== Ngày ra văn bản =================-->
              <b-col
                md="4"
                xl="4"
              >
                <b-form-group>
                  <label for="InputHelp">Ngày ra văn bản</label>
                  <date-time-picker
                    v-model="dataCreateLicense.dateUsingWorkerString"
                    :disabledInput="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </validation-observer>
      <div class="add-business__button">
        <div class="add-business__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="handleCreate"
          >
            Lưu lại
          </b-button>
        </div>
        <div class="add-business__button__item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="close"
          >
            Quay về
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import CusttomTitle from '@/views/management-business/business/pages/components/InformationAndAdressTitle.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    vSelect,
    DateTimePicker,
    BFormInput,
    BFormGroup,
    BButton,
    ValidationProvider,
    ValidationObserver,
    CusttomTitle,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      dataCreateLicense: {
        workerId: '',
        officalProfileId: '',
        numberUsingWorker: '',
        dateUsingWorkerString: '',
        usingWorkerCreateBy: '',
        dateStartString: '',
        dateEndString: '',
        locationRegister: '',
        reasonRequire: '',
        numberRequire: '',
        type: 0,
      },
      dataWorkerLicenseId: {},
      WorkerLicenseId: '',
    }
  },
  computed: {
    ...mapGetters('workerPermit', ['dataOfficalProfile', 'dataWorkerNotRegister']),
  },
  watch: {
    dataWorkerLicenseId(val) {
      if (val) {
        this.dataCreateLicense = {
          workerId: this.dataWorkerLicenseId.workerId,
          officalProfileId: this.dataWorkerLicenseId.officalProfileId,
          numberUsingWorker: this.dataWorkerLicenseId.numberUsingWorker,
          dateUsingWorkerString: this.dataWorkerLicenseId.dateUsingWorkerString,
          usingWorkerCreateBy: this.dataWorkerLicenseId.usingWorkerCreateBy,
          dateStartString: this.dataWorkerLicenseId.dateStartString,
          dateEndString: this.dataWorkerLicenseId.dateEndString,
          locationRegister: this.dataWorkerLicenseId.locationRegister,
          reasonRequire: this.dataWorkerLicenseId.reasonRequire,
          numberRequire: this.dataWorkerLicenseId.numberRequire,
          type: 0,
        }
      }
    },
  },
  mounted() {
    this.fetchDataComboboxProfile()
    if (!this.$route.params.id) {
      this.fetchDataComboboxWorker()
    }
  },
  created() {
    if (this.$route.params.id) {
      this.WorkerLicenseId = this.$route.params.id
      this.fetchDataDetail(this.WorkerLicenseId)
    }
  },
  methods: {
    ...mapActions('workerPermit', ['fetchDataComboboxProfile', 'fetchDataComboboxWorker']),

    // Lấy dữ liệu chi tiết
    async fetchDataDetail() {
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_WORKER_LICENSE}${this.WorkerLicenseId}`)
      this.dataWorkerLicenseId = data
      await this.fetchDataComboboxWorker(this.dataWorkerLicenseId.workerId)
    },

    async editWorkerPermit() {
      const data = {
        ...this.dataCreateLicense,
        id: this.$route.params.id,
      }
      try {
        this.$showAllPageLoading()
        const res = await axiosApiInstance.put(ConstantsApi.EDIT_REQURIE_LICENSE, data)
        if (res.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Sửa thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$router.push({ name: 'work-permit' })
        }
      } catch (err) {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(`${err.response.data?.errors[0]}`, {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      }
    },

    async createWorkerPermit() {
      const data = {
        ...this.dataCreateLicense,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.CREATE_REQUIRE_LICENSE, data).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Tạo thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$router.push({ name: 'work-permit' })
      }).catch(err => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(`${err.response.data?.errors[0]}`, {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    handleCreate() {
      const datestart = new Date(this.dataCreateLicense.dateStartString).getTime()
      const dateEnd = new Date(this.dataCreateLicense.dateEndString).getTime()

      if (datestart > dateEnd) {
        this.$root.$bvToast.toast('Ngày kết thúc không nhỏ hơn ngày bắt đầu', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      } else {
        this.save()
      }
    },

    // Tạo giấy cấp phép
    save() {
      this.$refs.rule.validate().then(success => {
        if (success) {
          if (this.$route.params.id) {
            this.editWorkerPermit()
          } else {
            this.createWorkerPermit()
          }
        }
      })
    },
    close() {
      this.$router.push({ name: 'work-permit' })
    },
  },
}
</script>

<style lang="scss">
#addBusiness{
   .add-business__button{
     padding-top:40px;
     display: flex;
     &__item{
       margin-right: 20px;
     }
   }
}

</style>
